var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-row',[_c('v-col',{key:_vm.componentKey,staticStyle:{"border":"1px solid gray"},attrs:{"md":"3"}},[_c('v-text-field',{attrs:{"label":"Suche...","flat":"","hide-details":"","clearable":"","clear-icon":"mdi-close-circle-outline"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),(_vm.FrontendProductstructure)?_c('v-treeview',{attrs:{"items":_vm.FrontendProductstructure,"hoverable":"","open-on-click":"","search":_vm.search,"filter":_vm.filter,"active":_vm.active,"open":_vm.open,"activatable":""},on:{"update:active":function($event){_vm.active=$event},"update:open":function($event){_vm.open=$event}},scopedSlots:_vm._u([{key:"prepend",fn:function({ item }){return [(!item.children)?_c('v-chip',{staticClass:"ma-2",attrs:{"color":item.name.substring(2, 3).toUpperCase() === 'B'
                ? 'indigo'
                : 'red',"text-color":"white"}},[_vm._v(" "+_vm._s(item.name.substring(2, 3).toUpperCase() === "B" ? "base" : "peak")+" ")]):_vm._e()]}}],null,false,3318736634)}):_vm._e()],1),_c('v-col',{staticStyle:{"border":"1px solid grey"},attrs:{"md":"9"}},[_c('v-container',{attrs:{"fluid":""}},[(_vm.active.length > 0)?_c('v-row',{attrs:{"dense":""}},_vm._l((_vm.Aufschlagskategorien),function(risktype){return _c('v-col',{key:risktype.index,attrs:{"cols":"6"}},[_c('v-card',[_c('v-card-text',[_c('v-simple-table',{attrs:{"height":"300px","dense":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v(_vm._s(risktype))]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}})],1)]},proxy:true},{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-left"},[_vm._v(_vm._s(_vm.tabellenHeaderNachKategorie[risktype].Beschreibung))]),_c('th',{staticClass:"text-left"},[_vm._v(_vm._s(_vm.tabellenHeaderNachKategorie[risktype].Wert))]),_c('th')])]),_c('tbody',{key:_vm.dataKey},[_vm._l((_vm.CurrentProductAufschlaege[risktype]),function(aufschlag,index){return _c('tr',{key:index},[_c('td',[(aufschlag.showtype == 'kategorie')?_c('v-icon',{attrs:{"color":"orange darken-2"}},[_vm._v("mdi-arrow-up-bold-box-outline")]):_vm._e(),_vm._v(" "+_vm._s(aufschlag.beschreibung)+" ")],1),_c('td',[_vm._v(_vm._s(aufschlag.wert))]),_c('td',[(
                              _vm.activeParsed.produkttyp === 'kategorien' ||
                              (_vm.activeParsed.produkttyp === 'produkte' &&
                                aufschlag.showtype === 'produkt')
                            )?_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editAufschlag(aufschlag)}}},[_vm._v(" mdi-pencil ")]):_vm._e(),(
                              _vm.activeParsed.produkttyp === 'kategorien' ||
                              (_vm.activeParsed.produkttyp === 'produkte' &&
                                aufschlag.showtype === 'produkt')
                            )?_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deleteAufschlag(aufschlag)}}},[_vm._v(" mdi-delete ")]):_vm._e()],1)])}),_c('tr',[_c('td',{attrs:{"colspan":"2"}}),_c('td',[_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.addNewAufschlag(risktype)}}},[_vm._v(" mdi-plus")])],1)])],2)]},proxy:true}],null,true)})],1)],1)],1)}),1):_vm._e()],1)],1)],1),(_vm.editAufschlag_item)?_c('v-dialog',{attrs:{"width":"800"},model:{value:(_vm.editDialog),callback:function ($$v) {_vm.editDialog=$$v},expression:"editDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5 grey lighten-2"},[_vm._v(" Produkt bearbeiten ")]),_c('v-card-text',{staticStyle:{"padding-top":"10px"}},[_c('v-text-field',{attrs:{"type":"text","label":"Beschreibung"},model:{value:(_vm.editAufschlag_item.beschreibung),callback:function ($$v) {_vm.$set(_vm.editAufschlag_item, "beschreibung", $$v)},expression:"editAufschlag_item.beschreibung"}}),_c('v-text-field',{attrs:{"type":"text","label":"Wert"},model:{value:(_vm.editAufschlag_item.wert),callback:function ($$v) {_vm.$set(_vm.editAufschlag_item, "wert", $$v)},expression:"editAufschlag_item.wert"}})],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":_vm.saveAufschlag}},[_vm._v(" Speichern ")]),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":_vm.abortSaveAufschlag}},[_vm._v(" Abbruch ")])],1)],1)],1):_vm._e()],1)
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }